
<template>
  <fieldsRows
    :templateContent="templateContent"
    @field-value-changed="updateFieldValue(...arguments)"
    :valueLine="stepStoredValue"
  ></fieldsRows>
</template>
<script>
import fieldsRows from "@/commonComponents/fieldsRows.vue";
export default {
  components: { fieldsRows },
  data() {
    return {};
  },
  props: ["result", "stepStoredValue", "stepType", "stepID"],
  computed: {
    templateContent() {
      let templateContent = {
        fields: {
          value: {
            type: "text",
            name: "Duration (Seconds)",
            fieldAttrInput: { class: "required digitalVariable" },
          },
          comment: { type: "textarea", name: "Comment" },
        },
        //contentID: this.stepType + "_" + this.stepID,
        contentID: this.stepType,
      };

      // templateContent.fields.value.value = this.stepStoredValue.value;
      //templateContent.fields.comment.value = this.stepStoredValue.comment;
      templateContent.disableAllInputs = this.result.disableAllInputs;
      return templateContent;
    },
  },
  methods: {
    updateFieldValue(field, value) {
      let localStoredStep = { ...this.stepStoredValue };
      localStoredStep[field] = value;
      this.$emit("stored-value-changed", localStoredStep);
    },
  },
};
</script>


